import { derived, writable } from 'svelte/store';
import Dry from '@app/scripts/json-dry.js';

export const initialSampleStore = {
  STRUCTURED_SAMPLE: [],
  RANDOM_SAMPLE: []
};

const STORAGE_NAME = 'wcag-em-report-tool-sample-store';
const serialisedStoredSampleStore = localStorage.getItem(STORAGE_NAME);
const storedSampleStore = Dry.parse(serialisedStoredSampleStore);
const sampleStore = writable(storedSampleStore || {...initialSampleStore});

sampleStore.subscribe(value => {
  const serialisedStoredSampleStore = Dry.stringify(value);

  localStorage.setItem(STORAGE_NAME, serialisedStoredSampleStore);
});


export const allSamples = derived(sampleStore, ($sampleStore) => {
  return [
    ...$sampleStore['STRUCTURED_SAMPLE'],
    ...$sampleStore['RANDOM_SAMPLE']
  ];
});

export default sampleStore;
