import { derived } from 'svelte/store';
import { json, t } from 'svelte-i18n';
import markdown from 'marked';

export const translate = derived([t], ([$t]) => {
  return (key, options) => {
    const translated = $t(key, options);

    let result = markdown.parseInline(translated);

    // Marked likes to turn single quotes into HTML entities,
    // undo that because it looks awful
    if (result.indexOf('&#39;') > -1) {
        result = result.replace(/\&\#39\;/g, "'");
    }

    return result;
  };
});

export const translateToObject = json;

