import { writable } from 'svelte/store';

export const initialSummaryStore = {
  EVALUATION_TITLE: '',
  EVALUATION_COMMISSIONER: '',
  EVALUATION_CREATOR: '',
  EVALUATION_DATE: new Date().toDateString(),
  EVALUATION_SUMMARY: '',
  EVALUATION_SPECIFICS: ''
};

const STORAGE_NAME = 'wcag-em-report-tool-summary-store';
const serialisedStoredSummaryStore = localStorage.getItem(STORAGE_NAME);
const storedSummaryStore = JSON.parse(serialisedStoredSummaryStore);
const summaryStore = writable(storedSummaryStore || {...initialSummaryStore});

summaryStore.subscribe(value => {
  const serialisedStoredSummaryStore = JSON.stringify(value);

  localStorage.setItem(STORAGE_NAME, serialisedStoredSummaryStore);
});

export default summaryStore;
