import { writable } from 'svelte/store';

import collection from './collectionStore.js';

import webTechnologies from '@app/data/webtechnologies.json';

export const initialExploreStore = {
  TECHNOLOGIES_RELIED_UPON: [],
  ESSENTIAL_FUNCTIONALITY: '',
  PAGE_TYPES: ''
};

export const webTechnologyStore = collection(null, [...webTechnologies]);

const STORAGE_NAME = 'wcag-em-report-tool-storage-store';
const serialisedStoredExploreStore = localStorage.getItem(STORAGE_NAME);
const storedExploreStore = JSON.parse(serialisedStoredExploreStore);
const exploreStore = writable(storedExploreStore || {...initialExploreStore});

exploreStore.subscribe(value => {
  const serialisedStoredExploreStore = JSON.stringify(value);

  localStorage.setItem(STORAGE_NAME, serialisedStoredExploreStore);
});

export default exploreStore;
