import { Base as BaseModel, partsMixin } from '@app/stores/earl/models.js';
import Dry from '@app/scripts/json-dry.js';

export const TestCriterionTypes = {
  TESTCRITERION: 'TestCriterion',
  TESTREQUIREMENT: 'TestRequirement',
  TESTCASE: 'TestCase'
};

let instances = new Map();

class TestCriterion extends partsMixin(BaseModel) {
  constructor(id, options) {
    super(options);

    if (arguments.length < 2) {
      throw new Error('The ' + this.constructor.name + ' constructor expects an ID and Options parameter');
    }

    const { TESTCRITERION } = TestCriterionTypes;

    Object.assign(this['@context'], {
      [TESTCRITERION]: 'earl:TestCriterion'
    });

    this.type = [TESTCRITERION];
    this.id = id;

    instances.set(this.id, this);
  }

  toDry() {
    let data = {id: this.id};
    return {value: data};
  }
}

class TestRequirement extends TestCriterion {
  constructor(id, options) {
    super(id, options);

    const { TESTREQUIREMENT } = TestCriterionTypes;

    Object.assign(this['@context'], {
      [TESTREQUIREMENT]: 'earl:TestRequirement'
    });

    this.type.push(TESTREQUIREMENT);
  }
}

TestRequirement.unDry = function unDry(data) {

  let id = data.id;

  let result = instances.get(id);

  if (!result) {

    // Work around for previous duplicate id
    if (id == 'WCAG21:accessible-documentation') {
      result = instances.get('WCAG21:accessible-documentation-c1224');

      if (result) {
        return result;
      }
    }

    throw new Error('Failed to revive TestRequirement with id "' + data.id + '"');
  }

  return result;
}

// This is not used?
class TestCase extends TestCriterion {
  constructor(id, options) {
    super(id, options);

    const { TESTCASE } = TestCriterionTypes;

    Object.assign(this['@context'], {
      [TESTCASE]: 'earl:TestCase'
    });

    this.type.push(TESTCASE);
  }
}

Dry.registerClass('TestRequirement', TestRequirement);
Dry.registerClass('TestCase', TestCase);
Dry.registerClass('TestCriterion', TestCriterion);

export {TestCase, TestRequirement}