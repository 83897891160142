import * as Dry from 'json-dry';

// Svelte minified all JavaScript on the live site,
// and thus renamed *every* class to `n`, which broke all JSON-DRY reviving.
const N = class n {};
N.unDry = function unDry(data) {
	if (typeof data.id == 'string') {
		if (data.id.indexOf('WCAG21') === 0) {
			return Dry.Classes.TestRequirement.unDry(data);
		} else {
			return Dry.Classes.OutcomeValue.unDry(data);
		}
	}

	if (Array.isArray(data.type)) {
		let type = data.type[0];

		if (type == 'Assertion') {
			return Dry.Classes.Assertion.unDry(data);
		}

		if (type == 'TestResult') {
			return Dry.Classes.TestResult.unDry(data);
		}

		if (type == 'OutcomeValue') {
			return Dry.Classes.OutcomeValue.unDry(data);
		}

		if (type == 'TestCriterion') {
			return Dry.Classes.TestCriterion.unDry(data);
		}
	}

	if (data['@type'] == 'Evaluation') {
		return Dry.Classes.EvaluationModel.unDry(data);
	}

	console.log('Failed to revive data:', data);
};

Dry.registerClass('n', N);
Dry.registerClass('e', N);

export default Dry