import { writable } from 'svelte/store';

export const initialScopeStore = {
  ADDITIONAL_REQUIREMENTS: '',
  AS_BASELINE: '',
  CONFORMANCE_TARGET: 'AA',
  SITE_NAME: '',
  WCAG_VERSION: '2.1',
  WEBSITE_SCOPE: ''
};

const STORAGE_NAME = 'wcag-em-report-tool-scope-store';
const serialisedStoredScopeStore = localStorage.getItem(STORAGE_NAME);
const storedScopeStore = JSON.parse(serialisedStoredScopeStore);
const scopeStore = writable(storedScopeStore || {...initialScopeStore});

scopeStore.subscribe(value => {
  const serialisedStoredScopeStore = JSON.stringify(value);

  localStorage.setItem(STORAGE_NAME, serialisedStoredScopeStore);
});


export default scopeStore;
